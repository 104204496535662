import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  Search, AlertCircle, ChevronLeft, ChevronDown, 
  ChevronRight, Home, Shield, UserCheck, BookOpen,
  ArrowRight, CheckCircle2, MessageCircle, Star
} from 'lucide-react';
import PropertyForm from './PropertyForm';
import config from '../config';

const CheckEligibilityPage2 = () => {
  // Keeping all existing state and functions
  const location = useLocation();
  const [postcode, setPostcode] = useState('');
  const [properties, setProperties] = useState([]);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [certificate, setCertificate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [step, setStep] = useState(1);
  const [activeTooltip, setActiveTooltip] = useState(null);

  // New state for manual address
  const [isManualAddress, setIsManualAddress] = useState(false);
  const [manualAddress, setManualAddress] = useState({
    addressLine1: '',
    addressLine2: '',
    city: '',
    county: '',
    postcode: ''
  });

  // Existing useEffect and functions remain the same
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const urlPostcode = searchParams.get('postcode');
    if (urlPostcode) {
      setPostcode(urlPostcode);
      performSearch(urlPostcode);
    }
  }, [location]);

  const performSearch = async (searchPostcode) => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get(`${config.apiUrl}/api/properties?postcode=${searchPostcode}`);
      if (response.data.length === 0) {
        setError('No properties found for this postcode. Please check and try again.');
      } else {
        setProperties(response.data);
        setSelectedProperty(null);
        setCertificate(null);
        setStep(2);
      }
    } catch (error) {
      setError('Failed to fetch properties. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();
    performSearch(postcode);
  };

  const handleManualAddressSubmit = (e) => {
    e.preventDefault();
    const formattedAddress = {
      address: `${manualAddress.addressLine1}${manualAddress.addressLine2 ? ', ' + manualAddress.addressLine2 : ''}, ${manualAddress.city}, ${manualAddress.county}`,
      'lmk-key': 'MANUAL',
      postcode: manualAddress.postcode // Add postcode separately
    };
    setSelectedProperty(formattedAddress);
    // Since this is a manual address, we'll skip the certificate fetch
    setCertificate({ property_type: 'Not Available', built_form: 'Not Available' });
    setStep(3);
  };

  const handlePropertySelect = async (e) => {
    const selectedValue = e.target.value;
    if (selectedValue === 'manual') {
      setIsManualAddress(true);
      return;
    }

    const selectedPropertyIndex = selectedValue;
    const property = properties[selectedPropertyIndex];
    
    // Create new property object with postcode
    const propertyWithPostcode = {
      ...property,
      postcode: postcode // Include postcode from the search
    };
    
    setSelectedProperty(propertyWithPostcode);
    setLoading(true);
    setError(null);
    
    const lmkKey = property['lmk-key'];
    if (!property || !lmkKey) {
      setError('Unable to fetch certificate due to missing data.');
      setLoading(false);
      return;
    }

    try {
      const response = await axios.get(`${config.apiUrl}/api/certificate/${lmkKey}`);
      if (response.data && response.data.rows && response.data.rows.length > 0) {
        setCertificate(response.data.rows[0]);
        setStep(3);
      } else {
        setError('No certificate data found for this property.');
      }
    } catch (error) {
      setError('Failed to fetch certificate. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const testimonials = [
    {
      name: "Frederick A",
      content: "Excellent arrangement of installation. The engineers were highly professional and did their job timely. I couldn't be more happy with the services they provided.",
      rating: 5
    },
    {
      name: "Monica R",
      content: "From start to finish, EcoShine Solar provided the best and smooth experience. I am happy I chose them for the process. I will recommend them again and again.",
      rating: 5
    },
    {
      name: "Linda N",
      content: "I was not expecting this job to be done as quickly as these guys made it. Super fast, extremely polite, and hassle-free procedure with them. They made all my worries vanish in just 2 days.",
      rating: 5
    },
    {
      name: "Betty L",
      content: "The installation of the boiler via the EcoShine Solar team was one of the best decisions I have ever made in my life. They did their job quickly and were highly professional. They made sure not to make me worry for a single second and completed the whole process smoothly. Would highly recommend them to anyone wishing for installation of grants.",
      rating: 5
    }
  ];

  const features = [
    {
      icon: <Shield className="w-12 h-12 text-green-500" />,
      title: "Tap into our network",
      description: "We have a list of registered installers who are in contract with well-known energy suppliers"
    },
    {
      icon: <UserCheck className="w-12 h-12 text-green-500" />,
      title: "User-friendly",
      description: "We have a smooth application process. Paperless, just apply online now."
    },
    {
      icon: <MessageCircle className="w-12 h-12 text-green-500" />,
      title: "Our priority YOU",
      description: "We are here for you, anything to make your application successful."
    },
    {
      icon: <BookOpen className="w-12 h-12 text-green-500" />,
      title: "Expert Guidance",
      description: "We provide expert guidance to have a seamless understanding of the whole process."
    }
  ];

  const ProgressBar = () => (
    <div className="flex justify-between mb-8 relative">
      <div className="absolute top-7 left-0 w-full h-1 bg-gray-200">
        <div 
          className="h-full bg-green-500 transition-all duration-500"
          style={{ width: `${((step - 1) / 2) * 100}%` }}
        />
      </div>
      {[1, 2, 3].map((s) => (
        <div key={s} className="relative z-10 flex flex-col items-center">
          <motion.div 
            className={`w-14 h-14 rounded-full flex items-center justify-center cursor-pointer
              ${step >= s ? 'bg-green-500 text-white' : 'bg-gray-200 text-gray-500'}
              transition-colors duration-300 hover:shadow-lg`}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.95 }}
          >
            {s === 1 ? <Search className="w-6 h-6" /> : 
             s === 2 ? <Home className="w-6 h-6" /> : 
             <ChevronRight className="w-6 h-6" />}
          </motion.div>
          <span className={`mt-2 text-sm ${step >= s ? 'text-green-500 font-semibold' : 'text-gray-500'}`}>
            {s === 1 ? 'Search' : s === 2 ? 'Select' : 'Check'}
          </span>
        </div>
      ))}
    </div>
  );

  return (
    <div className="pt-32 min-h-screen bg-gradient-to-b from-green-50 to-white">
      <div className="mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-center max-w-5xl mx-auto mb-16"
        >
          <h1 className="text-4xl md:text-5xl font-bold text-gray-900 mb-4">
            Check Your Eligibility
          </h1>
          <p className="text-lg md:text-xl text-gray-600 mb-8">
            Get a free, no-commitment assessment to check your eligibility instantly.
            Take the first step towards a warmer home!
          </p>
          <motion.div 
            className="bg-green-100 rounded-lg p-4 mb-8"
            whileHover={{ scale: 1.02 }}
          >
            <div className="flex items-center justify-center space-x-2">
              <CheckCircle2 className="w-12 h-12 text-green-600" />
              <p className="text-green-800 font-medium">
                Entrusted by Thousands of Brits, for helping them get free government grants
              </p>
            </div>
          </motion.div>
        </motion.div>

        <motion.div 
          className="max-w-5xl mx-auto bg-white rounded-2xl shadow-xl overflow-hidden mb-16"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2 }}
        >
          <div className="px-6 py-8 sm:p-10">
            <ProgressBar />

            <AnimatePresence mode="wait">
              {step === 1 && (
                <motion.div
                  key="search"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  transition={{ duration: 0.3 }}
                >
                  <form onSubmit={handleSearch} className="space-y-6">
                    <div>
                      <label htmlFor="postcode" className="block text-xl font-medium text-gray-700 mb-4">
                        Tell us your postcode to find the perfect energy-saving solutions for your home
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                          <Search className="h-6 w-6 text-gray-400" />
                        </div>
                        <input
                          type="text"
                          id="postcode"
                          value={postcode}
                          onChange={(e) => setPostcode(e.target.value.toUpperCase())}
                          placeholder="Enter your postcode"
                          className="focus:ring-green-500 focus:border-green-500 block w-full pl-12 pr-4 py-4 text-lg border-gray-300 rounded-md transition-all duration-200 hover:border-green-300"
                          required
                          pattern="^[A-Z]{1,2}[0-9][A-Z0-9]? ?[0-9][A-Z]{2}$"
                          title="Please enter a valid UK postcode"
                        />
                      </div>
                    </div>
                    <motion.button 
                      type="submit" 
                      disabled={loading}
                      className="w-full flex justify-center items-center py-4 px-4 border border-transparent rounded-md shadow-sm text-lg font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 transition duration-150 ease-in-out disabled:opacity-50"
                      whileHover={{ scale: 1.02 }}
                      whileTap={{ scale: 0.98 }}
                    >
                      {loading ? (
                        <>
                          <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-white mr-3"></div>
                          Checking...
                        </>
                      ) : (
                        <>
                          Check Eligibility Now
                          <ChevronRight className="ml-2 h-5 w-5" />
                        </>
                      )}
                    </motion.button>
                  </form>
                </motion.div>
              )}

{step === 2 && properties.length > 0 && !isManualAddress && (
  <motion.div
    key="select"
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    exit={{ opacity: 0, y: -20 }}
    transition={{ duration: 0.3 }}
    className="space-y-6"
  >
    <h2 className="text-2xl font-semibold text-gray-900 mb-6">Select your property:</h2>
    <div className="space-y-4">
      <div className="relative">
        <select
          onChange={handlePropertySelect}
          className="block appearance-none w-full bg-white border border-gray-300 hover:border-green-400 px-4 py-4 pr-8 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-green-500 focus:border-green-500 text-lg transition-all duration-200"
          defaultValue=""
        >
          <option value="" disabled>Choose a property</option>
          {properties.map((property, index) => (
            <option key={index} value={index}>
              {property.address}
            </option>
          ))}
        </select>
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
          <ChevronDown className="h-5 w-5" />
        </div>
      </div>

      <div className="relative">
        <motion.button
          onClick={() => setIsManualAddress(true)}
          className="w-full flex items-center justify-center space-x-2 py-4 px-4 border-2 border-gray-300 rounded-md text-lg font-medium text-gray-700 hover:border-green-500 hover:text-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 transition duration-150 ease-in-out"
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
        >
          <Home className="h-5 w-5" />
          <span>My address is not listed, enter manually</span>
        </motion.button>
      </div>
    </div>
  </motion.div>
)}

  {step === 2 && isManualAddress && (
    <motion.div
      key="manual-address"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      transition={{ duration: 0.3 }}
      className="space-y-6"
    >
      <div className="flex items-center mb-6">
        <button
          onClick={() => setIsManualAddress(false)}
          className="flex items-center text-green-600 hover:text-green-700 transition-colors duration-200"
        >
          <ChevronLeft className="h-5 w-5 mr-1" />
          Back to address selection
        </button>
      </div>
      
      <h2 className="text-2xl font-semibold text-gray-900 mb-6">Enter your address manually:</h2>
      
      <form onSubmit={handleManualAddressSubmit} className="space-y-4">
        <div>
          <label htmlFor="addressLine1" className="block text-sm font-medium text-gray-700">
            Address Line 1 *
          </label>
          <input
            type="text"
            id="addressLine1"
            required
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-green-500 focus:border-green-500"
            value={manualAddress.addressLine1}
            onChange={(e) => setManualAddress({...manualAddress, addressLine1: e.target.value})}
          />
        </div>
        
        <div>
          <label htmlFor="addressLine2" className="block text-sm font-medium text-gray-700">
            Address Line 2
          </label>
          <input
            type="text"
            id="addressLine2"
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-green-500 focus:border-green-500"
            value={manualAddress.addressLine2}
            onChange={(e) => setManualAddress({...manualAddress, addressLine2: e.target.value})}
          />
        </div>
        
        <div>
          <label htmlFor="city" className="block text-sm font-medium text-gray-700">
            City/Town *
          </label>
          <input
            type="text"
            id="city"
            required
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-green-500 focus:border-green-500"
            value={manualAddress.city}
            onChange={(e) => setManualAddress({...manualAddress, city: e.target.value})}
          />
        </div>
        
        <div>
          <label htmlFor="county" className="block text-sm font-medium text-gray-700">
            County *
          </label>
          <input
            type="text"
            id="county"
            required
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-green-500 focus:border-green-500"
            value={manualAddress.county}
            onChange={(e) => setManualAddress({...manualAddress, county: e.target.value})}
          />
        </div>
        
        <div>
          <label htmlFor="postcode" className="block text-sm font-medium text-gray-700">
            Postcode *
          </label>
          <input
            type="text"
            id="postcode"
            required
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-green-500 focus:border-green-500"
            value={manualAddress.postcode}
            onChange={(e) => setManualAddress({...manualAddress, postcode: e.target.value.toUpperCase()})}
          />
        </div>
        
        <motion.button
          type="submit"
          className="w-full flex justify-center items-center py-3 px-4 border border-transparent rounded-md shadow-sm text-lg font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 transition duration-150 ease-in-out"
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
        >
          Continue
          <ChevronRight className="ml-2 h-5 w-5" />
        </motion.button>
      </form>
    </motion.div>
  )}


              {step === 3 && selectedProperty && certificate && (
                <motion.div
                  key="form"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  transition={{ duration: 0.3 }}
                >
                  <PropertyForm
                    selectedProperty={selectedProperty}
                    certificate={certificate}
                    postcode={postcode} // Add this line
                  />
                </motion.div>
              )}
            </AnimatePresence>

            {error && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                className="mt-6 bg-red-50 border-l-4 border-red-400 p-4 rounded-md"
              >
                <div className="flex">
                  <div className="flex-shrink-0">
                    <AlertCircle className="h-6 w-6 text-red-400" />
                  </div>
                  <div className="ml-3">
                    <p className="text-base text-red-700">{error}</p>
                  </div>
                </div>
              </motion.div>
            )}
          </div>
        </motion.div>

        {step === 1 && (
          <>
            <div className="max-w-7xl mx-auto mb-16">
              <h2 className="text-3xl font-bold text-center text-gray-900 mb-12">
                How can we serve
              </h2>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
                {features.map((feature, index) => (
                  <motion.div 
                    key={index}
                    className="bg-white rounded-xl shadow-lg p-6 text-center"
                    whileHover={{ y: -5 }}
                  >
                    <div className="flex justify-center mb-4">
                      {feature.icon}
                    </div>
                    <h3 className="text-xl font-semibold text-gray-900 mb-2">
                      {feature.title}
                    </h3>
                    <p className="text-gray-600">
                      {feature.description}
                    </p>
                  </motion.div>
                ))}
              </div>
            </div>

            <div className="max-w-5xl mx-auto mb-16 text-center">
              <motion.div 
                className="bg-green-50 rounded-xl p-8"
                whileHover={{ scale: 1.02 }}
              >
                <h2 className="text-2xl font-bold text-gray-900 mb-4">
                  Funded by Energy Suppliers
                </h2>
                <p className="text-gray-600">
                  The Energy Company Obligation is a scheme funded by energy companies to meet their carbon reduction targets.
                </p>
              </motion.div>
            </div>

            <div className="max-w-7xl mx-auto">
              <div className="text-center mb-12">
                <h2 className="text-3xl font-bold text-gray-900 mb-4">
                  Testimonials
                </h2>
                <p className="text-lg text-gray-600">
                  We are always happy to assist you throughout the process
                </p>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
                {testimonials.map((testimonial, index) => (
                  <motion.div
                    key={index}
                    className="bg-white rounded-xl shadow-lg p-6"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: index * 0.1 }}
                    whileHover={{ y: -5 }}
                  >
                    <div className="flex items-center mb-4">
                      <div className="ml-3">
                        <h3 className="font-semibold text-gray-900">{testimonial.name}</h3>
                        <div className="flex">
                          {[...Array(testimonial.rating)].map((_, i) => (
                            <Star key={i} className="w-4 h-4 text-yellow-400 fill-current" />
                          ))}
                        </div>
                      </div>
                    </div>
                    <p className="text-gray-600 text-sm leading-relaxed">
                      "{testimonial.content}"
                    </p>
                  </motion.div>
                ))}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default CheckEligibilityPage2;