import React, { useState, useRef, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronRight, ChevronLeft, Check, X, Leaf, Wind, Thermometer, Home, DollarSign } from 'lucide-react';
import {getEligibleGrants, getRecommendations} from '../utils'
import axios from 'axios';
import { Link } from 'react-router-dom';
import config from '../config';

const PropertyForm = ({ selectedProperty, certificate, postcode }) => { // Add postcode to props
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({
    relationship: '',
    heatingType: '',
    heatingAge: '',
    bedrooms: '',
    roofInsulation: '',
    wallType: '',
    benefits: [],
    householdIncome: '',
  });

  const SCROLL_OFFSET = 100; 

  const [eligibilityResults, setEligibilityResults] = useState(null);
  const [recommendations, setRecommendations] = useState([]);
  const [currentView, setCurrentView] = useState('form');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [showPersonalInfo, setShowPersonalInfo] = useState(false);
  const [personalInfo, setPersonalInfo] = useState({
    name: '',
    email: '',
    telephone: '',
    agreeTerms: false,
  });
  const [currentStep, setCurrentStep] = useState(0);


    // Refs for scrolling
    const formRef = useRef(null);
    const questionRef = useRef(null);

  // Scroll to question when step changes with offset
  useEffect(() => {
    if (questionRef.current) {
      const yOffset = -SCROLL_OFFSET;
      const element = questionRef.current;
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

      window.scrollTo({
        top: y,
        behavior: 'smooth'
      });
    }
  }, [currentStep]);

  const resetForm = () => {
    setPersonalInfo({
      firstName: '',
      lastName: '',
      email: '',
      phone: ''
    });
    setSubmitSuccess(false);
    setError(null);
  };
  
  const formSteps = [
    {
      name: 'relationship',
      label: 'What is your relationship to the property?',
      description: 'We use this to tailor our recommendations and to suggest possible grants that may be available to you.',
      icon: Home,
      options: [
        { value: 'homeowner', label: 'Homeowner' },
        { value: 'privateTenant', label: 'Private tenant' },
        { value: 'landlord', label: 'Landlord' },
        { value: 'housingAssociation', label: 'Housing Association tenant' },
        { value: 'councilTenant', label: 'Council tenant' },
        { value: 'other', label: 'Other' },
      ],
    },
    {
      name: 'heatingType',
      label: 'What is the main way you heat your home?',
      description: 'This helps us understand your current energy setup and suggest appropriate improvements.',
      assistance: certificate['mainheat-description']
      ? (
        <p className="bg-green-500 text-white p-4 text-center">
          According to the EPC, the main heating type is: <strong className='text-green-900'>{certificate['mainheat-description']}</strong>
        </p>
      )
      : (
        <p>
        </p>
      )
      ,
      icon: Thermometer,
      options: [
        { value: 'gasRoomHeater', label: 'Gas room heater' },
        { value: 'backBoiler', label: 'Back boiler' },
        { value: 'gasBoiler', label: 'Gas boiler' },
        { value: 'electricBoiler', label: 'Electric boiler' },
        { value: 'electricRoomHeater', label: 'Electric room heater' },
        { value: 'electricStorageHeater', label: 'Electric storage heater' },
        { value: 'lpgBoiler', label: 'LPG boiler' },
        { value: 'oilBoiler', label: 'Oil boiler' },
        { value: 'solidFuelHeating', label: 'Solid fuel heating' },
        { value: 'airSourceHeatPump', label: 'Air source heat pump' },
        { value: 'groundSourceHeatPump', label: 'Ground source heat pump' },
        { value: 'noHeating', label: 'No heating at all' },
        { value: 'warmAirGasSystem', label: 'Warm Air Gas System' },
        { value: 'other', label: 'Other' },        
      ],
    },
    {
      name: 'heatingAge',
      label: 'Approximately how old is your heating system?',
      description: 'The age of your heating system can impact its efficiency and eligibility for certain upgrades.',
      icon: Wind,
      options: [
        { value: '0-5', label: '0-5 years' },
        { value: '6-10', label: '6-10 years' },
        { value: '11-15', label: '11-15 years' },
        { value: '16+', label: '16+ years' },
      ],
    },
    {
      name: 'bedrooms',
      label: 'How many bedrooms does the property have?',
      description: 'This helps us estimate the size of your property and potential energy needs.',
      icon: Home,
      options: [
        { value: '1', label: '1 bedroom' },
        { value: '2', label: '2 bedrooms' },
        { value: '3', label: '3 bedrooms' },
        { value: '4', label: '4 bedrooms' },
        { value: '5', label: '5 bedrooms' },
        { value: '6', label: '6 or more bedrooms' },
      ],
    },
    {
      name: 'roofInsulation',
      label: 'What type of roof insulation do you have?',
      description: 'Proper roof insulation can significantly reduce heat loss and improve energy efficiency.',
      assistance: certificate['roof-description']
      ? (
        <p className="bg-green-500 text-white p-4 text-center">
          According to the EPC, the roof type is: <strong className='text-green-900'>{certificate['roof-description']}</strong>
        </p>
      )
      : (
        <p>
        </p>
      )
      ,
      icon: Home,
      options: [
        { value: 'pitchedRoofInsulated', label: 'Pitched roof - insulated' },
        { value: 'pitchedRoofNotInsulated', label: 'Pitched roof - not insulated' },
        { value: 'roomInRoofInsulated', label: 'Room in roof - insulated' },
        { value: 'roomInRoofNotInsulated', label: 'Room in roof - not insulated' },
        { value: 'flatRoofInsulated', label: 'Flat roof - insulated' },
        { value: 'flatRoofNotInsulated', label: 'Flat roof - not insulated' },
        { value: 'dontKnow', label: 'I don\'t know' },
        { value: 'other', label: 'Other' },        
      ],
    },
    {
      name: 'wallType',
      label: 'What type of walls do you have?',
      description: 'Different wall types require different insulation methods and can affect heat retention.',
      assistance: certificate['walls-description']
      ? (
        <p className="bg-green-500 text-white p-4 text-center">
          According to the EPC, the wall type is: <strong className='text-green-900'>{certificate['walls-description']}</strong>
        </p>
      )
      : (
        <p>
        </p>
      )
      ,
      icon: Home,
      options: [
        { value: 'cavityWallsUninsulated', label: 'Cavity walls (uninsulated)' },
        { value: 'cavityWallsInsulated', label: 'Cavity walls (insulated)' },
        { value: 'solidWallsUninsulated', label: 'Solid walls (uninsulated)' },
        { value: 'solidWallsInsulated', label: 'Solid walls (insulated)' },
        { value: 'dontKnow', label: 'I don\'t know' },
        { value: 'other', label: 'Other' },

      ],
    },
    {
      name: 'benefits',
    label: 'Does anyone in your household claim any of the following benefits or tax credits?',
    description: 'Some grants and support schemes are available based on your household benefit status.',
    icon: DollarSign,
    options: [
      { value: 'Child Benefit', label: 'Child Benefit' },
      { value: 'Universal Credit', label: 'Universal Credit' },
      { value: 'Pension Credit (Guarantee Credit)', label: 'Pension Credit' },
      { value: 'Income-based Jobseeker\'s Allowance', label: 'Jobseeker\'s Allowance' },
      { value: 'Income-related Employment and Support Allowance', label: 'Employment Allowance' },
      { value: 'Income Support', label: 'Income Support' },
      { value: 'Working Tax Credit', label: 'Working Tax Credit' },
      { value: 'Child Tax Credit', label: 'Child Tax Credit' },
      { value: 'Housing Benefit', label: 'Housing Benefit' },
      { value: 'Pension Credit (Savings Credit)', label: 'Pension Credit' },
      { value: 'none', label: 'None of the above' },
      ],
    },
    {
      name: 'householdIncome',
      label: 'What is your annual household income?',
      description: 'This information helps determine eligibility for certain financial support schemes.',
      icon: DollarSign,
      options: [
        { value: '19000', label: 'Under £20,000' },
        { value: '21000', label: '£20,000 - £30,000' },
        { value: '31001', label: '£30,000 - £40,000' },
        { value: '41000', label: '£40,000 - £50,000' },
        { value: '51000', label: 'Over £50,000' },
      ],
    },
  ];

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => {
      if (name === 'benefits') {
        if (value === 'none') {
          // If 'None of the above' is selected, clear all other selections
          return { ...prevData, benefits: checked ? ['none'] : [] };
        } else if (type === 'checkbox') {
          let newBenefits;
          if (checked) {
            // Add the benefit and remove 'none' if it exists
            newBenefits = [...prevData.benefits.filter(b => b !== 'none'), value];
          } else {
            // Remove the benefit
            newBenefits = prevData.benefits.filter(b => b !== value);
          }
          return { ...prevData, benefits: newBenefits };
        }
      }
      return { ...prevData, [name]: value };
    });
  };


  const handlePersonalInfoChange = (e) => {
    const { name, value, type, checked } = e.target;
    setPersonalInfo((prevInfo) => ({
      ...prevInfo,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleSubmit = () => {
    const grants = getEligibleGrants(formData, certificate);
    const recs = getRecommendations(formData, certificate);
    setEligibilityResults(grants);
    setRecommendations(recs);
    setCurrentView('results');
  };

  const nextStep = () => {
    if (currentStep < formSteps.length - 1) {
      setCurrentStep(currentStep + 1);
    } else {
      handleSubmit();
    }
  };

  const prevStep = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleContinue = () => {
    setCurrentView('personalInfo');
  };

  const handlePersonalInfoSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setIsSubmitting(true);
  
    try {
      // Validate required fields
      if (!personalInfo.name?.trim() || !personalInfo.email?.trim() || !personalInfo.telephone?.trim()) {
        throw new Error('Please fill in all required fields.');
      }
  
      // Validate email format
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(personalInfo.email)) {
        throw new Error('Please enter a valid email address.');
      }
  
      // Validate terms agreement
      if (!personalInfo.agreeTerms) {
        throw new Error('Please agree to the terms and conditions.');
      }
  
      const payload = {
        ...formData,
        name: personalInfo.name.trim(),
        email: personalInfo.email.trim(),
        telephone: personalInfo.telephone.trim(),
        eligibleGrants: eligibilityResults || [],
        property: {
          ...selectedProperty,
          postcode: selectedProperty.postcode || postcode // Use either the property postcode or the passed postcode
        },
        certificate: certificate || null,
        isManualAddress: !selectedProperty || selectedProperty['lmk-key'] === 'MANUAL'
      };
  
      const response = await axios.post(`${config.apiUrl}/api/submit-form`, payload);
      
      if (response.status === 201) {
        setSubmitSuccess(true);
        setError(null);
      } else {
        throw new Error('Submission failed. Please try again.');
      }
    } catch (error) {
      console.error('Submission error:', error);
      setError(error.response?.data?.error || error.message || 'An unexpected error occurred. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };
  

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPersonalInfo(prev => ({
      ...prev,
      [name]: value
    }));
  };

     // Enhanced navigation component
  const renderNavigation = () => (
    <div className="fixed bottom-0 left-0 right-0 bg-white border-t border-gray-200 p-4 flex justify-between items-center z-50">
      <div className="w-full max-w-5xl mx-auto flex justify-between items-center">
        {currentStep > 0 ? (
          <button
            type="button"
            onClick={prevStep}
            className="flex items-center px-4 py-2 text-green-600 hover:text-green-700 focus:outline-none transition-colors duration-150"
          >
            <ChevronLeft className="h-5 w-5 mr-1" />
            <span className="hidden sm:inline">Previous</span>
          </button>
        ) : (
          <div /> // Empty div for spacing
        )}
        
        <div className="flex items-center space-x-2">
          {Array(formSteps.length).fill(null).map((_, index) => (
            <div
              key={index}
              className={`h-2 w-2 rounded-full transition-all duration-200 ${
                index === currentStep 
                  ? 'bg-green-600 w-4' 
                  : index < currentStep 
                    ? 'bg-green-400' 
                    : 'bg-gray-300'
              }`}
            />
          ))}
        </div>

        <button
          type="button"
          onClick={nextStep}
          className="flex items-center px-4 py-2 text-white bg-green-600 rounded-lg hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 transition-colors duration-150"
        >
          <span>{currentStep === formSteps.length - 1 ? 'Submit' : 'Next'}</span>
          <ChevronRight className="h-5 w-5 ml-1" />
        </button>
      </div>
    </div>
  );

  const renderSuccessMessage = () => (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="bg-white p-8 rounded-lg shadow-lg text-center"
    >
      <Check className="mx-auto h-16 w-16 text-green-500 mb-4" />
      <h3 className="text-2xl font-bold text-green-800 mb-4">Successfully Submitted!</h3>
      <p className="text-lg text-gray-600 mb-6">
        Thank you for submitting your information. Our team will review your application and contact you soon with the next steps.
      </p>
      <p className="text-base text-gray-500">
        If you have any questions, please don't hesitate to reach out to our support team.
      </p>
    </motion.div>
  );

  const renderFormField = () => {
    const step = formSteps[currentStep];
    const fieldName = step.name;
  
    return (
      <motion.div
        ref={questionRef}
        className="bg-white p-6 sm:p-8 rounded-lg shadow-lg"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -20 }}
        transition={{ duration: 0.3 }}
      >
        <div className="mb-6">
          <div className="flex items-center space-x-3 mb-4">
            {React.createElement(step.icon, { className: "h-6 w-6 text-green-600" })}
            <h2 className="text-xl sm:text-2xl font-bold text-green-800">{step.label}</h2>
          </div>
          <p className="text-gray-600">{step.description}</p>
          {step.assistance && (
            <div className="mt-4">{step.assistance}</div>
          )}
        </div>

        <div className="grid grid-cols-1 gap-4 sm:gap-6">
          {step.options.map((option) => (
            <label
              key={option.value}
              className={`
                flex items-center p-4 rounded-lg border-2 cursor-pointer
                transition-all duration-200 transform hover:scale-[1.01]
                ${formData[fieldName] === option.value || 
                  (fieldName === 'benefits' && formData[fieldName].includes(option.value))
                  ? 'border-green-500 bg-green-50 shadow-md'
                  : 'border-gray-200 bg-white hover:border-green-300 hover:bg-green-50'}
              `}
            >
              <div className="flex items-center flex-1 min-h-[48px]">
                <input
                  type={fieldName === 'benefits' ? 'checkbox' : 'radio'}
                  name={fieldName}
                  value={option.value}
                  checked={fieldName === 'benefits' 
                    ? formData[fieldName].includes(option.value)
                    : formData[fieldName] === option.value
                  }
                  onChange={handleChange}
                  className="h-5 w-5 text-green-600 focus:ring-green-500 border-gray-300 rounded"
                />
                <span className="ml-3 text-base sm:text-lg text-gray-700">{option.label}</span>
              </div>
            </label>
          ))}
        </div>
      </motion.div>
    );
  };

  const [isPopupOpen, setIsPopupOpen] = useState(false);

const togglePopup = () => {
  setIsPopupOpen(!isPopupOpen);
};


  const renderResults = () => (
    <motion.div
      className="bg-white shadow-lg sm:rounded-lg overflow-hidden"
      initial={{ opacity:0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <div className="px-4 py-5 sm:p-6">
        <h3 className="text-2xl font-bold text-green-800 mb-6">Eligibility Results</h3>
        {eligibilityResults.length > 0 ? (
          <div className="space-y-6">
            <p className="text-lg text-gray-600">Great news! You may be eligible for the following grants:</p>
            <ul className="space-y-4">
              {eligibilityResults.map((grant, index) => (
                <li key={index} className="bg-green-50 rounded-lg p-6 shadow-sm">
                  <div className="flex items-start">
                    <div className="flex-shrink-0">
                      <Check className="h-8 w-8 text-green-400" aria-hidden="true" />
                    </div>
                    <div className="ml-4">
                      <h4 className="text-xl font-semibold text-green-800">{grant.name}</h4>
                      <ul className="mt-3 text-base text-green-700 list-disc list-inside">
                        {grant.reasons.map((reason, idx) => (
                          <li key={idx} className="mb-2">{reason}</li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
            <div className="mt-8 bg-white rounded-lg p-6 shadow-sm">
              <h4 className="text-xl font-semibold text-green-800 mb-4">Recommended Energy Improvements</h4>
              <ul className="space-y-3">
                {recommendations.map((rec, index) => (
                  <li key={index} className="flex items-center text-base text-gray-600">
                    <Leaf className="h-6 w-6 text-green-500 mr-3" />
                    {rec}
                  </li>
                ))}
              </ul>
            </div>
            <button
              onClick={handleContinue}
              className="mt-8 w-full flex justify-center py-3 px-4 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 transition duration-150 ease-in-out"
            >
              Continue to Application
            </button>
          </div>
        ) : (
          <div className="space-y-6">
            <div className="rounded-lg bg-yellow-50 p-6 shadow-sm">
              <div className="flex">
                <div className="flex-shrink-0">
                  <X className="h-6 w-6 text-yellow-400" aria-hidden="true" />
                </div>
                <div className="ml-3">
                  <h3 className="text-lg font-medium text-yellow-800">
                    Not eligible for ECO4 or LADS grants
                  </h3>
                  <div className="mt-2 text-base text-yellow-700">
                    <p>Based on the information provided, it appears you may not be eligible for ECO4 or LADS grants at this time.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-sm">
              <h4 className="text-xl font-semibold text-green-800 mb-4">Energy Efficiency Recommendations</h4>
              <ul className="space-y-3">
                {recommendations.map((rec, index) => (
                  <li key={index} className="flex items-center text-base text-gray-600">
                    <Leaf className="h-6 w-6 text-green-500 mr-3" />
                    {rec}
                  </li>
                ))}
              </ul>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-sm">
              <h4 className="text-xl font-semibold text-green-800 mb-4">Next Steps</h4>
              <ul className="space-y-3 text-base text-gray-600">
                <li className="flex items-center">
                  <Check className="h-6 w-6 text-green-500 mr-3" />
                  Check with your local authority for any local energy efficiency programs.
                </li>
                <li className="flex items-center">
                  <Check className="h-6 w-6 text-green-500 mr-3" />
                  Consider implementing some energy-saving measures on your own to reduce energy bills.
                </li>
                <li className="flex items-center">
                  <Check className="h-6 w-6 text-green-500 mr-3" />
                  Keep an eye out for new schemes or changes to existing ones that might make you eligible in the future.
                </li>
              </ul>
            </div>
            <p className="text-base text-gray-500 mt-6">
              If you believe there's been an error or if your circumstances change, please feel free to check again.
            </p>
          </div>
        )}
      </div>
    </motion.div>
  );


  
  const renderPersonalInfoForm = () => (
    <motion.form
      onSubmit={handlePersonalInfoSubmit}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="bg-white p-8 rounded-lg shadow-lg"
    >
      <h3 className="text-2xl font-bold text-green-800 mb-6">Personal Information</h3>
      <div className="space-y-6">
        <div>
          <label htmlFor="name" className="block text-sm font-medium text-gray-700">Name</label>
          <input
            type="text"
            id="name"
            name="name"
            value={personalInfo.name}
            onChange={handlePersonalInfoChange}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
            required
          />
        </div>
        <div>
          <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
          <input
            type="email"
            id="email"
            name="email"
            value={personalInfo.email}
            onChange={handlePersonalInfoChange}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
            required
          />
        </div>
        <div>
          <label htmlFor="telephone" className="block text-sm font-medium text-gray-700">Telephone</label>
          <input
            type="tel"
            id="telephone"
            name="telephone"
            value={personalInfo.telephone}
            onChange={handlePersonalInfoChange}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
            required
          />
        </div>
        <div className="flex items-start">
          <div className="flex items-center h-5">
            <input
              id="agreeTerms"
              name="agreeTerms"
              type="checkbox"
              checked={personalInfo.agreeTerms}
              onChange={handlePersonalInfoChange}
              className="focus:ring-green-500 h-4 w-4 text-green-600 border-gray-300 rounded"
              required
            />
          </div>
  
          <div className="ml-3 text-sm">
            <label htmlFor="agreeTerms" className="font-medium text-gray-700">
            I agree to the{' '}
            <span
              onClick={togglePopup}
              className="text-green-600 underline cursor-pointer"
            >
              terms and conditions and privacy policy
            </span>
          </label>
          </div>
          
        </div>
        {isPopupOpen && (
          <motion.div 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="relative top-30 inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4"
          >
            <motion.div
              initial={{ scale: 0.9, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              transition={{ type: "spring", stiffness: 300, damping: 20 }}
              className="bg-white w-full max-w-3xl max-h-[90vh] rounded-2xl shadow-2xl overflow-hidden flex flex-col"
            >
              <div className="bg-green-800 text-white p-6 flex justify-between items-center">
                <h2 className="text-2xl font-bold">Privacy Policy</h2>
                <button 
                  onClick={togglePopup} 
                  className="hover:bg-green-700 p-2 rounded-full transition"
                >
                  <X size={24} />
                </button>
              </div>
              
              <div className="p-6 overflow-y-auto prose prose-green max-w-none">
                <div className="prose-p:text-gray-700 prose-headings:text-green-800">
                  {/* Exact same content from original */}
                  <h1>Privacy Policy for Eco Solar Shine</h1>
                  <p>At Eco Solar Shine, protecting your privacy is important to us. This Privacy Policy explains how we collect, use, and safeguard your personal information when you interact with our lead generation services, website, or communication channels.</p>
  
                  {/* Render rest of the original privacy policy content */}
                  <h2 className='font-bold'>1. Information We Collect</h2>
                  <p>As a lead generation company, we collect personal information to connect you with suitable service providers. The types of information we collect include:</p>
  
                  <h3>a. Information You Provide Directly:</h3>
                  <ul>
                    <li>Name</li>
                    <li>Contact details (email address, phone number, postal address)</li>
                    <li>Information related to your inquiry (e.g., details about solar or eco-friendly services of interest)</li>
                  </ul>
                  <h3>b. Automatically Collected Information:</h3>
                  <ul>
                    <li>IP address</li>
                    <li>Device and browser information</li>
                  </ul>
  
                  <h3>c. Third-Party Sources:</h3>
                  <ul>
                    <li>Information from partner companies or publicly available data that aligns with your interests</li>
                  </ul>

                  <h2 className='font-bold'>2. How We Use Your Information</h2>
                  <p>We use the personal information collected to:</p>
                  <ol>
                    <li>Match you with relevant service providers or partners</li>
                    <li>Respond to your inquiries and provide tailored recommendations</li>
                    <li>Enhance and personalise your user experience</li>
                    <li>Communicate with you about offers, updates, or promotions (with your consent)</li>
                    <li>Improve our website and marketing strategies through analytics</li>
                    <li>Comply with legal and regulatory obligations</li>
                  </ol>
  
                  <h2 className='font-bold'>3. Sharing Your Information</h2>
                  <p>Eco Solar Shine may share your information with trusted third parties under the following conditions:</p>
                  <ul>
                    <li>Service Providers: We share your information with vetted partners or companies that provide the products or services you're interested in.</li>
                    <li>Legal Requirements: When required by law, regulation, or government authorities.</li>
                    <li>Business Transactions: In the event of a merger, acquisition, or sale of our business.</li>
                  </ul>
  
                  <p>We do not sell your personal information to unaffiliated third parties for their direct marketing purposes.</p>

                  <h2 className='font-bold'>4. Legal Basis for Processing (For UK/EU Residents)</h2>
                  <p>If you are located in the UK or EU, we process your data under the following legal bases:
  <ul><li>Consent: When you provide clear permission for us to process your data (e.g., by submitting a contact form).</li>	
	<li>Legitimate Interests: For lead generation and connecting you with relevant providers.</li>
	<li>Legal Obligation: To comply with applicable laws or regulations.</li></ul>                  
	
</p>
                  <h2 className='font-bold'>5. Your Rights</h2>
                  <p>Depending on your location, you may have the following rights regarding your data:
                    <ol><li>Access: Request a copy of the personal information we hold about you.</li>
	<li>Rectification: Correct any inaccuracies in your information.</li>
	<li>Erasure: Request that we delete your personal data, subject to legal or contractual obligations.</li>
	<li>Objection: Opt-out of certain data processing activities, such as marketing.</li>
	<li>Data Portability: Request a transfer of your information to another service provider.</li></ol>
	

To exercise your rights, please contact us at ecosolarshine@gmail.com.

</p>
                  <h2 className='font-bold'>6. Retention of Data</h2>
                  <p>We retain your personal information only as long as necessary to fulfill the purposes outlined in this policy or comply with legal requirements.
                  </p>
                  <h2 className='font-bold'>7. Security Measures</h2>
                  <p>We implement strict security measures, including encryption and secure storage, to protect your personal data from unauthorized access, alteration, or loss.
                  </p>
                  <h2 className='font-bold'>8. Cookies and Tracking</h2>
                  <p>Eco Solar Shine uses cookies and tracking technologies to enhance website performance and analyze user behavior. You can adjust your cookie preferences in your browser settings.
                  </p>
                  <h2 className='font-bold'>9. Third-Party Links</h2>
                  <p>Our website or communication may include links to external websites. Eco Solar Shine is not responsible for the privacy practices or content of these third-party sites.
                  </p>
                  <h2 className='font-bold'>10. Changes to This Policy</h2>
                  <p>We may update this Privacy Policy to reflect changes in our operations or legal requirements. Updates will be posted on our website with a revised effective date.
                  </p>
                  <h2 className='font-bold'>11. Contact Us</h2>
                  <p>If you have questions about this Privacy Policy or wish to exercise your rights, contact us at ecosolarshine@gmail.com </p>
                </div>
              </div>
            </motion.div>
          </motion.div>
        )}
      </div>
      <div className="mt-8">
        <button
          type="submit"
          disabled={isSubmitting}
          className={`w-full flex justify-center py-3 px-4 border border-transparent rounded-md shadow-sm text-base font-medium text-white ${
            isSubmitting ? 'bg-green-400 cursor-not-allowed' : 'bg-green-600 hover:bg-green-700'
          } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 transition duration-150 ease-in-out`}
        >
          {isSubmitting ? 'Submitting...' : 'Submit'}
        </button>
      </div>
    </motion.form>
  );



  return (
    <div ref={formRef} className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 py-8 pb-24">
      <AnimatePresence mode="wait">
        {currentView === 'form' && (
          <>
            {renderFormField()}
            {renderNavigation()}
          </>
        )}
        {currentView === 'results' && (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.3 }}
          >
            {renderResults()}
          </motion.div>
        )}
        {currentView === 'personalInfo' && !submitSuccess && (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.3 }}
          >
            {renderPersonalInfoForm()}
          </motion.div>
        )}
        {submitSuccess && (
          <motion.div
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.3 }}
          >
            {renderSuccessMessage()}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default PropertyForm;