import React, { useState, useEffect } from 'react';
import { Menu, X, Leaf, Home, FileText, Gift, PhoneCall } from 'lucide-react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <>
      <footer className="bg-white-900 text-white py-12 px-4">
        <div className="bg-green-800 text-gray-300 py-4 px-4 mb-6 rounded-lg">
          <h3 className="text-lg font-semibold text-white">Disclaimer</h3>
          <p className="text-sm mt-2 leading-6">
            By submitting your information on this website, you acknowledge and agree that your personal data may be shared with third-party installers who specialise in insulation, solar, and heating solutions. 
            This information will be used solely for the purpose of providing you with quotes, advice, and details regarding the services you have expressed interest in. 
            Please note that third-party installers operate independently, and we are not responsible for their services, products, or any agreements you may enter with them.
            <br />
            For more information on how your data is used and protected, please refer to our Privacy Policy and Terms of Service.
          </p>
        </div>
        <div className="max-w-8xl mx-auto grid md:grid-cols-3 gap-8 lg:gap-12">
          <div className="space-y-4 md:col-span-1">
            <Link to="/" className="flex items-center">
              <img 
                src="/assets/logo.png" 
                alt="EcoShine Solar Logo" 
                className="h-24 md:h-14 lg:h-24"
              />
            </Link>
            <p className="text-gray-600 whitespace-nowrap text-sm lg:text-base">Powering Tomorrow with Sustainable Energy Today</p>
          </div>
          
          {[
            {
              title: 'Quick Links',
              items: ['Home', 'Blogs', 'Grants', 'Contact Us'],
              links: ['/', '/blogs', '/grants', '/contact']
            },
            {
              title: 'Get in Touch',
              items: [
                <span key="phone" className="flex items-center"><PhoneCall className="h-4 w-4 mr-2" /> +44 07723371387</span>,
                'info.ecoshinesolar@gmail.com ',
                '84 Hayling Road WD197BP',
                'Hertfordshire, UK'
              ],
              links: null
            }
          ].map(({ title, items, links }) => (
            <div key={title} className="space-y-4 md:col-span-1">
              <h3 className="font-semibold text-lg">{title}</h3>
              <ul className="space-y-3 text-gray-600">
                {items.map((item, index) => (
                  <li key={index}>
                    {typeof item === 'string' && links ? (
                      <Link to={links[index]} className="hover:text-white transition-colors">
                        {item}
                      </Link>
                    ) : (
                      item
                    )}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </footer>
      <footer className="bg-green-900 text-white py-4 text-center">
        <div className="container mx-auto px-4">
          EcoShine Solar | Renewable Energy Solutions | © 2024 All Rights Reserved | Brightening the World One Panel at a Time
        </div>
      </footer>
    </>
  );
};

export default Footer;