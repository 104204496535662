import React, { useState, useRef } from 'react';
import { Mail, Phone, MapPin, Send } from 'lucide-react';
import emailjs from '@emailjs/browser';

const ContactUsPage = () => {
  const form = useRef();
  const [formData, setFormData] = useState({
    user_name: '',
    user_email: '',
    message: ''
  });
  const [formStatus, setFormStatus] = useState({ type: '', message: '' });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setFormStatus({ type: '', message: '' });

    try {
      // Initialize EmailJS if not already done
      emailjs.init(process.env.REACT_APP_EMAILJS_PUBLIC_KEY);
      
      const result = await emailjs.sendForm(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        form.current
      );

      if (result.text === 'OK') {
        setFormStatus({
          type: 'success',
          message: "Thank you! We'll get back to you soon."
        });
        setFormData({ user_name: '', user_email: '', message: '' });
      }
    } catch (error) {
      console.error('Email error:', error);
      setFormStatus({
        type: 'error',
        message: 'Failed to send message. Please try again.'
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-green-50 to-white py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-4xl mx-auto">
        <h1 className="pt-24 text-4xl font-bold text-green-800 text-center mb-8">Contact Us</h1>
        <p className="text-green-700 mb-12 text-center">
          Have questions about energy-saving grants or need assistance? We're here to help!
        </p>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
          <div>
            <h2 className="text-2xl font-semibold text-green-800 mb-6">Get in Touch</h2>
            {formStatus.message && (
              <div className={`mb-4 p-4 rounded ${formStatus.type === 'success' ? 'bg-green-50 text-green-800' : 'bg-red-50 text-red-800'}`}>
                <p>{formStatus.message}</p>
              </div>
            )}
            <form ref={form} onSubmit={handleSubmit} className="space-y-6">
              <div>
                <label htmlFor="user_name" className="block text-sm font-medium text-green-700">Name</label>
                <input
                  type="text"
                  id="user_name"
                  name="user_name"  // Changed from "name"
                  value={formData.user_name}
                  onChange={handleChange}
                  required
                  className="mt-1 block w-full px-3 py-2 bg-white border border-green-300 rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500"
                />
              </div>
              <div>
                <label htmlFor="user_email" className="block text-sm font-medium text-green-700">Email</label>
                <input
                  type="email"
                  id="user_email"
                  name="user_email"  // Changed from "email"
                  value={formData.user_email}
                  onChange={handleChange}
                  required
                  className="mt-1 block w-full px-3 py-2 bg-white border border-green-300 rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500"
                />
              </div>
              <div>
                <label htmlFor="message" className="block text-sm font-medium text-green-700">Message</label>
                <textarea
                  id="message"
                  name="message"
                  rows="4"
                  value={formData.message}
                  onChange={handleChange}
                  required
                  className="mt-1 block w-full px-3 py-2 bg-white border border-green-300 rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500"
                ></textarea>
              </div>
              <button
                type="submit"
                disabled={isSubmitting}
                className="w-full flex justify-center items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 disabled:opacity-50"
              >
                {isSubmitting ? 'Sending...' : 'Send Message'}
                <Send className="ml-2 h-4 w-4" />
              </button>
            </form>
          </div>

          <div>
            <h2 className="text-2xl font-semibold text-green-800 mb-6">Contact Information</h2>
            <div className="space-y-4">
              <p className="flex items-center text-green-700">
                <Phone className="h-5 w-5 mr-2 text-green-600" />
                +44 07723371387
              </p>
              <p className="flex items-center text-green-700">
                <Mail className="h-5 w-5 mr-2 text-green-600" />
                info.ecoshinesolar@gmail.com 
              </p>
              <p className="flex items-center text-green-700">
                <MapPin className="h-5 w-5 mr-2 text-green-600" />
                84 Hayling Road WD197BP, Hertfordshire, UK
              </p>
            </div>

            <div className="mt-8">
              <h3 className="text-xl font-semibold text-green-800 mb-4">Our Location</h3>
              <iframe
                title="Office Location"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2475.8046419621086!2d-0.3796892!3d51.6424053!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48761349b0ce7e15%3A0x9e9c3f6d52c781f9!2s84%20Hayling%20Rd%2C%20Watford%20WD19%207BP!5e0!3m2!1sen!2suk!4v1669651885716!5w!3h!4m2!3m1!1s0x48761349b0ce7e15%3A0x9e9c3f6d52c781f9"
                width="100%"
                height="300"
                className="rounded-lg border-2 border-green-200"
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUsPage;